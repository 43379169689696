import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-479e79a4"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  key: 0,
  class: "toast"
};
import { ref, watch } from 'vue';
export default {
  __name: 'index',
  props: {
    show: {
      type: Boolean,
      default: false
    },
    msg: {
      type: String,
      default: 'message'
    },
    duration: {
      type: Number,
      default: 1500
    }
  },
  emits: ['update:show'],
  setup(__props, {
    emit: __emit
  }) {
    const props = __props;
    const isShow = ref(props.show);
    const emit = __emit;
    watch(() => props.show, (newVal, oldVal) => {
      isShow.value = newVal;
      if (newVal) {
        clearInterval(timer);
        var timer = setTimeout(() => {
          isShow.value = false;
          emit('update:show', false);
        }, props.duration);
      }
    });
    return (_ctx, _cache) => {
      return isShow.value ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(__props.msg), 1)) : _createCommentVNode("", true);
    };
  }
};