import { ref } from "vue";
const active = ref(0);
export default {
  setup() {
    return {
      active
    };
  },
  mounted() {},
  methods: {}
};