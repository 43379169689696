import { defineAsyncComponent, ref } from "vue";
import { mapState, mapActions } from "vuex";
import clip from "./util/clipboard.js";
export default {
  components: {
    chatPage: defineAsyncComponent(() => import("./view/home/components/chatPage.vue"))
  },
  data() {
    return {
      open: false
    };
  },
  watch: {
    $route(to, from) {
      // 在每次路由切换前，将页面滚动到顶部
      window.scrollTo(0, 0);
    }
  },
  computed: {
    ...mapState("ws", ["systemSettingsList", "redEnvelopeInfo"]),
    ...mapState(["deviceType", "chatPageShow", "chatPageOpen"]),
    routeData() {
      return this.$route;
    },
    // 红包开关
    isHongBaoOpen() {
      let data = this.systemSettingsList.filter(k => {
        return k.settingKey === "red_envelope_switch";
      })[0] || {};
      return data.settingValue === "1";
    }
  },
  created() {
    // window.history.pushState(null, null, document.URL);
    // window.addEventListener('popstate', function () {
    //   // 每当用户试图返回上一页时，重新添加一个历史记录
    //   window.history.pushState(null, null, document.URL);
    // });
    this.createWebSocket();
  },
  mounted() {
    this.ping();
    window.addEventListener("unload", this.saveState);
    setTimeout(() => {
      this.open = true;
    }, 200);
  },
  methods: {
    ...mapActions("ws", ["createWebSocket"]),
    ...mapActions(["toPath", "ping"]),
    saveState() {
      // 重置参数
      sessionStorage.setItem("state", JSON.stringify(this.$store.state));
    },
    //复制
    handleCopy(text, event) {
      clip(text.toFixed(1) / 1 + "px", event);
    },
    onChatPageOpen() {
      if (sessionStorage["sessionId"]) {
        // this.toPath("null");
        this.$store.commit("SET_chatPage", true);
      } else {
        this.toPath("/login");
      }
    },
    onClose() {
      this.$store.state.loadButShow = false;
      this.$store.state.chatPageShow = false;
    },
    onChatPageOpen() {
      if (sessionStorage["sessionId"]) {
        this.$store.commit("SET_chatPage", true);
      } else {
        this.toPath("/login");
      }
    }
  }
};